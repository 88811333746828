<template>
    <custom-table
        :filter_settings="activityLogFilters"
        :filter_items="filterItems"
        :page_filters="page_filters"
        :filters="filters"
        :headers_items="headerItems"
        :loading="loading || !items"
        :content-type="contentType"
        :items="items"
        :options="tableOptions"
        @getData="getList($event)"
        @selectAllCheckbox="selectAllCheckbox($event)"
    />
</template>

<script>
import contentBaseMixin from '@/mixins/content/contentBaseMixin'
import { headerItems } from '@/helpers/constants/activity-log/content-table-header-items'
import { FilterItems, Filters } from '@/helpers/constants/activity-log/filters'
import { activityLogFilters } from '@/helpers/filters-settings/activity-log-filters'

import {
    ACTION_GET_ACTIVITY_LOG,
    ACTION_GET_USERS
} from '@/store/modules/activity-log/action-types'
import { createNamespacedHelpers, mapState } from 'vuex'
import { debounce, isEqual } from 'lodash';

const {
    mapActions: mapActivityLogActions
} = createNamespacedHelpers('activityLog')

export default {
    name: 'Index',
    mixins: [
        contentBaseMixin
    ],
    data() {
        return {
            tableOptions: {
                filters: true,
                content: true,
                toolbar: false,
                hiddenModals: false
            },
            filters: {},
            activityLogFilters
        }
    },
    computed: {
        ...mapState('activityLog', ['activityLog']),
        headerItems() {
            return headerItems
        },
        items() {
            return this.activityLog
        },
        filterItems() {
            return FilterItems
        },
        page_filters() {
            return Filters
        }
    },
    watch: {
        '$route.query': debounce(function (val) {
            if (this.$route.name !== 'activity-log') return
            if (isEqual(this.$route.query, this.filters)) return
            this.filters = {
                ...val
            }
            this.getList(val)
        }, 50)
    },
    mounted() {
        this.getUsers()
    },
    methods: {
        ...mapActivityLogActions([
            ACTION_GET_ACTIVITY_LOG,
            ACTION_GET_USERS
        ]),
        getList(query) {
            this[ACTION_GET_ACTIVITY_LOG]({ ...query })
        },
        async getUsers() {
            const data = await this[ACTION_GET_USERS]()
            this.activityLogFilters.lists.fieldsList = data
        }
    }
}
</script>

<style scoped>

</style>
