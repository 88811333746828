export const activityLogFilters = {
    config: {
        rowsPerPage: true,
        paginationPage: true,
        status: false,
        search: false
    },
    lists: {
        fieldsList: [
        ]
    }
}
